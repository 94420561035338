import React  from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import "@fortawesome/fontawesome-free/js/all";
import { Helmet } from 'react-helmet';

import Home from './Home'
import PlantMonitor from './PlantMonitor'
import BlogOverview from './BlogOverview'
import BlogPost from './BlogPost'
import BouncyBoxLandingPage from './BouncyBoxLandingPage/BouncyBox';
import SilentDotLandingPage from './SilentDotLandingPage/SilentDot';

import './App.scss'

const App = () => {
  return (
    <div className="wrapper">
      <Helmet>
        <meta name="twitter:card" content="summary" /> 
        <meta name="twitter:site" content="@SanderVhove" /> 
        <meta name="twitter:creator" content="@SanderVhove" />
        <meta name="twitter:title" content="Sander Vanhove" /> 
        <meta name="twitter:description" content="Sander Vanhove is a passionate indie game developer working on Silent Dot." /> 
        <meta name="twitter:image" content="https://www.sandervanhove.com/twitter-summary-card.png" />
        <meta name="twitter:image:alt" content="Sander's face in vector art." />

        <link rel="canonical" href="https://www.sandervanhove.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:local" content="en_US" />
        <meta property="og:title" content="Sander Vanhove" />
        <meta property="og:url" content="https://www.sandervanhove.com/" />
        <meta property="og:site_name" content="Sander Vanhove" />
        <meta property="og:description" content="Sander Vanhove is a passionate indie game developer working on Silent Dot." /> 
        <meta property="og:image" content="https://www.sandervanhove.com/facebook-card.png" />
      </Helmet>

      <BrowserRouter>
        <Switch>
          <Route component={PlantMonitor} path="/plant-monitor" />
          <Route component={BlogPost} path="/blog/:slug" />
          <Route component={BlogOverview} path="/blog" />
          <Route component={BouncyBoxLandingPage} path="/bouncy-box" />
          <Route component={SilentDotLandingPage} path="/silent-dot" />
          <Route component={Home} path="/" />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App
