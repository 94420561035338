import React, { useEffect, useState } from 'react'

import { debounce } from 'lodash'

import Header from '../Header'
import Face from '../Face'
import Content from '../Content'

import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import MailForm from '../MailForm'

import './Home.scss'

const Home = () => {
  const [dimensions, setDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', debounce(handleResize, 500))
  })

  if (dimensions.width >= 930) {
    return (
      <Container className="App non-mobile home">
        <Helmet>
          <title>Sander Vanhove</title>
        </Helmet>
        <Header></Header>
        <Row>
          <Col sm md={7}>
            <Content></Content>
          </Col>
          <Col sm>
            <Face></Face>
          </Col>
        </Row>
        <MailForm></MailForm>
        <p className="text-center mt-5">
          <a href="/presskit/">Press Kit</a>
        </p>
      </Container>
    )
  }

  return (
    <Container className="App home">
      <Helmet>
        <title>Sander Vanhove</title>
      </Helmet>
      <Face></Face>
      <Header></Header>
      <Content></Content>
      <MailForm></MailForm>
      <p className="text-center mt-5">
        <a href="/presskit/">Press Kit</a>
      </p>
    </Container>
  )
}

export default Home
