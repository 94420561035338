import React from "react"
import LandingPage from "../LandingPage"

import icon from './icon.png'
import videoPoster from './video_poster.png'
import screenshot01 from './screenshot_gameplay_01.png'
import screenshot02 from './screenshot_gameplay_02.png'
import screenshot03 from './screenshot_gameplay_03.png'
import screenshot04 from './screenshot_gameplay_04.png'

const SilentDotTheme = React.lazy(() => import('./SilentDotTheme'))

const SilentDotLandingPage = () => {
    return (
        <div className="silent-dot-landingpage">
            <React.Suspense fallback={<></>}>
                <SilentDotTheme />
            </React.Suspense>
            <LandingPage
                gameName="Silent Dot"
                shortDescription={`Silent Dot is a minimalist puzzle game.

Move the dot in over 180 levels towards the triangle. Solve many fascinating puzzles. Easy to pick up, hard to master. A game for everyone who likes a good puzzle.`}
                icon={icon}
                introductionVideo="https://www.sandervanhove.com/Silent_Dot_Coming_Soon.mp4"
                appStoreLink="https://apps.apple.com/us/app/silent-dot/id1596890890"
                googlePlayLink="https://play.google.com/store/apps/details?id=com.sandervanhove.silentdot"
                itchEmbed='<iframe frameborder="0" src="https://itch.io/embed/1307047?bg_color=ffffff&amp;fg_color=222222&amp;link_color=f26e00&amp;border_color=bebebe" width="100%" height="167"><a href="https://sandervanhove.itch.io/silent-dot">Silent Dot by Sander Vanhove</a></iframe>'
                videoPoster={videoPoster}
                videoDimensions="574:1024"
                presskitLink="/presskit/silent_dot/"
                features={[
                    "+180 interesting levels",
                    "+180 beautiful color schemes",
                    "3 levels of difficulty",
                    "Minimalist art style",
                    "Chill music",
                    "7 languages",
                    "No ads!",
                ]}
                screenshots={[
                    screenshot01,
                    screenshot02,
                    screenshot03,
                    screenshot04,
                ]}
            />
        </div>
    )
}

export default SilentDotLandingPage