import React from "react"
import { Helmet } from 'react-helmet'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Link } from "react-router-dom"
/* @ts-ignore */
import { Player, BigPlayButton, ControlBar, VolumeMenuButton } from 'video-react';

import './LandingPage.scss'
import appStoreIcon from './appstore.svg'
import googlePlayIcon from './googleplay.svg'
import MailForm from "../MailForm"

class LandingPageParams {
    gameName: string = ""
    shortDescription: string = ""
    icon: string = ""
    introductionGif?: string = ""
    introductionVideo?: string = ""
    videoPoster?: string = ""
    videoDimensions?: string = ""
    appStoreLink?: string = ""
    googlePlayLink?: string = ""
    itchEmbed?: string = ""
    releaseDate?: string = ""
    releaseDateInt?: string = ""
    presskitLink: string = ""
    features?: string[] = []
    screenshots?: string[] = []
}

const LandingPage = (params: LandingPageParams) => {
    const {
        gameName,
        shortDescription,
        icon,
        introductionGif,
        introductionVideo,
        videoPoster,
        videoDimensions = "574:1024",
        appStoreLink,
        googlePlayLink,
        itchEmbed,
        releaseDate,
        releaseDateInt,
        presskitLink,
        features = [],
        screenshots = [],
    } = params

    const releaseDatePlanningLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${gameName}%20Release&dates=${releaseDateInt}&details=${window.location.href}`

    return (
        <Container className="App landing-page">
            <Helmet>
                <title>{gameName} | Sander Vanhove</title>
                <link rel="shortcut icon" type="image/x-icon" href={icon}/>
                
                <meta name="twitter:title" content={`${gameName} | Sander Vanhove`}/> 
                <meta name="twitter:description" content={shortDescription}/> 
                <meta name="twitter:image" content={icon}/>
                <meta name="twitter:image:alt" content={`${gameName} icon.`}/>

                <meta property="og:title" content={`${gameName} | Sander Vanhove`}/>
                <meta property="og:url" content={`https://www.sandervanhove.com/${gameName}`} />
                <meta property="og:description" content={shortDescription}/> 
                <meta property="og:image" content={icon}/>
            </Helmet>

            <Row>
                <Col sm md="5">
                    <div className="gameplay-gif">
                        {introductionGif &&
                            <Image src={introductionGif} alt={`Basic gameplay of ${gameName}.`} className="shadow"/>
                        }
                        {introductionVideo &&
                            <Player autoPlay muted={true} className="shadow" poster={videoPoster} aspectRatio={videoDimensions} loop playsInline >
                                <source src={introductionVideo} />
                                <BigPlayButton position="center" />
                                <ControlBar autoHideTime={300} disableDefaultControls>
                                    <VolumeMenuButton vertical />
                                </ControlBar>
                            </Player>
                        }
                    </div>
                </Col>
                <Col className="game-details mt-5 mt-md-0">
                    <Row className="header">
                        <Col xs="3" sm="3" md="3">
                            <Image className="app-icon" src={icon} alt={`${gameName} icon.`} />
                        </Col>
                        <Col>
                            <h1 className="game-name">{gameName}</h1>
                            <Link className="developer-name" to="/">by Sander Vanhove</Link>
                        </Col>
                    </Row>

                    <p className="short-description mt-3 mt-md-0">{shortDescription}</p>

                    <ul>
                        {features.map((feature, index) => (<li key={index}>{feature}</li>))}
                    </ul>

                    {googlePlayLink && appStoreLink && (
                    <Row sm="1" md="1" lg="2" className="mt-3 mt-md-0">
                        <Col>
                            <div className="store-link mb-sm-2">
                                <a href={googlePlayLink} target="_blank" rel="noreferrer">
                                    <Image src={googlePlayIcon} alt="Link to the Apple app store." />
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="store-link">
                                <a href={appStoreLink} target="_blank" rel="noreferrer">
                                    <Image src={appStoreIcon} alt="Link to the Apple app store." />
                                </a>
                            </div>
                        </Col>
                    </Row>
                    )}

                    {!googlePlayLink && !appStoreLink && (
                    <div className="coming-soon-message mt-3 mt-md-0">
                            {releaseDate && (<h3>Coming <a href={releaseDatePlanningLink} target="blank">{releaseDate}</a></h3>)}
                            {!releaseDate && (<h3>Coming soon</h3>)}
                            <p>👇 Stay updated by subscribing to the mail list 👇</p>
                    </div>
                    )}

                    {itchEmbed && (
                        <div className="itch-embed" dangerouslySetInnerHTML={{__html: itchEmbed}}>
                        </div>
                    )}
                </Col>
            </Row>

            <Row xs="2" md={4} className="screenshots justify-content-center">
                {screenshots.map((screenshot, index) => {
                    return (
                        <Col key={index}>
                            <Image src={screenshot} className="screenshot shadow" alt={`Screen shot ${index} of ${gameName}.`} />
                        </Col>
                    )
                })}
            </Row>

            <footer>
                <MailForm></MailForm>

                <ul className="extra-links">
                    <li>
                        <a href={presskitLink}>Press Kit</a>
                    </li>
                    <li>
                        <Link className="developer-name" to="/">Developed by Sander Vanhove</Link>
                    </li>
                </ul>
            </footer>

        </Container>
    )
}

export default LandingPage