import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import './about.scss'

const About = () => {
    return (
        <>
            <Helmet>
                <title>About | Sander Vanhove</title>
                <meta name="twitter:title" content="About | Sander Vanhove"/> 

                <meta property="og:title" content="About | Sander Vanhove"/>
                <meta property="og:url" content="https://www.sandervanhove.com/about/" />
            </Helmet>

            <div className="content-category">
                <p>Hey 👋😄 I'm a game developer from Belgium. Here's me in a few bullet points:</p>
            </div>

            <div className="content-category">
                <h6>Games:</h6>
                <ul>
                    <li>Developed my first games in math class on a graphical calculator.</li>
                    <li>Released a weird game about <a href="https://play.google.com/store/apps/details?id=com.lamasaurus.brood">eating bread 🍞</a>.</li>
                    <li>Love participating in <a href="https://itch.io/c/2091458/my-jam-games">Game Jams</a>.</li>
                    <li>Released a hyper casual game called <Link to="/bouncy-box">Bouncy Box ⬜</Link>.</li>
                    <li>Released a chill, minimalist movement puzzle game <Link to="/silent-dot">Silent Dot 🔴</Link>.</li>
                </ul>
            </div>
            <div className="content-category">
                <h6>Career:</h6>
                <ul>
                    <li>Graduated as Master in Computer Science 💻 from the <a href="https://www.ugent.be/en">UGhent</a>.</li>
                    <li>Worked at <a href="https://www.waylay.io/">Waylay</a> as Backend engineer/Data scientist 📊.</li>
                    <li>Worked at <a href="https://www.waylay.io/">Waylay</a> as Community Manager.</li>
                    <li>Currently lead game developer and technical artist at <a href="https://studiotolima.com/">Studio Tolima</a>.</li>
                    <li>Co-founded <a href="https://playing-grounds.com/">Playing Grounds</a>, roomscale-VR 🥽 and contract work.</li>
                </ul>
            </div>
            <div className="content-category">
                <h6>Random:</h6>
                <ul>
                    <li>Music 🎸 is my catharsis. See games ⤴ and <a href="https://soundcloud.com/sander-vanhove">here</a> for some of mine.</li>
                    <li><a href="https://www.instagram.com/p/ByfJTM_h0aS/?utm_source=ig_web_copy_link">Throwing yoyos</a> 🪀 is second nature to me. Yes, those are still a thing.</li>
                    <li>Hides from and explores the world when trekking 🥾.</li>
                </ul>
            </div>
            <div className="content-category">
                <h6>Personal core values:</h6>
                <ul>
                    <li><strong>Creativity:</strong> Love creating things 🎨. Not saying I'm good at it though.</li>
                    <li><strong>Optimism:</strong> Everything will be ok in the end! 🥛😁</li>
                    <li><strong>Health:</strong> Taking care of the last body I'll ever own 💪.</li>
                </ul>
            </div>
        </>
    )
}

export default About
