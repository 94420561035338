import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';

import './ShareIcons.scss'

const ShareIcons = (props: any) => {
    const { shareUrl, title } = props
    return (
        <Row className="share-icons">
            <Col className="share-icon">
                <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    className="share-icon__share-button"
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
            </Col>

            <Col className="share-icon">
                <FacebookMessengerShareButton
                    url={shareUrl}
                    appId="521270401588372"
                    className="share-icon__share-button"
                >
                    <FacebookMessengerIcon size={32} round />
                </FacebookMessengerShareButton>
            </Col>

            <Col className="share-icon">
                <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    className="share-icon__share-button"
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
            </Col>

            <Col className="share-icon">
                <LinkedinShareButton url={shareUrl} className="share-icon__share-button">
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
            </Col>

            <Col className="share-icon">
                <EmailShareButton
                    url={shareUrl}
                    subject={title}
                    body="body"
                    className="share-icon__share-button"
                >
                    <EmailIcon size={32} round />
                </EmailShareButton>
            </Col>
        </Row>
    )
}

export default ShareIcons