import React from "react"
import { Button, Form, FormControl, FormLabel, InputGroup } from "react-bootstrap"
import MailchimpSubscribe from "react-mailchimp-subscribe"

import './MailForm.scss'

class FormInput {
    status: "sending" | "error" | "success" | null | undefined
    message: string | Error | null | undefined
    onValidate: ((inputs: { EMAIL: string} ) => void) | undefined
}

const MailFormContent = ({status, message, onValidate}: FormInput) => {
    let email: string;
    const submit = () =>
        email &&
        email.indexOf("@") > -1 &&
        onValidate &&
        onValidate({
            EMAIL: email,
        });
    
    const setEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        email = event.target.value
    }
    
    return (
        <div className="mail-list-form">
            <Form action="" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                <div aria-hidden="true"><input type="text" name="b_afbe01155fc4e75959626374d_df1457bcfb" value="" hidden readOnly/></div>
                <FormLabel>Stay up to date</FormLabel>
                <p className="text-center">And get the soundtrack of Silent Dot for <b>FREE</b>!</p>
                {status === null &&
                    <InputGroup className="mb-3">
                        <FormControl
                            type="email"
                            onChange={setEmail}
                            placeholder="Email address"
                            aria-label="Email address"
                            aria-describedby="Email address for subscribing to the newsletter."
                        />
                        <Button className="submit-button" onClick={submit}>Submit</Button>
                    </InputGroup>
                }
                {status === "sending" && <p className="send-status sending">Sending... 😁</p>}
                {status === "error" && <p className="send-status error">Something went wrong, try again in a bit. 😕 </p>}
                {status === "success" && <p className="send-status success">Thanks for subscribing! 🤗 Please confirm your email address.</p>}
            </Form>

        </div>
    )
}

const MailForm = () => {
    const url = 'https://sandervanhove.us5.list-manage.com/subscribe/post?u=afbe01155fc4e75959626374d&amp;id=df1457bcfb'


    return (
        <MailchimpSubscribe 
            url={url}
            render={({ subscribe, status, message }) => (
                <MailFormContent
                    status={status}
                    message={message}
                    onValidate={formData => subscribe(formData)}
                    />
            )}
            />
    )
}

export default MailForm