import { useEffect } from 'react'

import { clamp, random } from 'lodash'

import './face.scss'
import { ReactComponent as FaceSVG } from './sanders_face.svg'

const Face = () => {
  let timeout: NodeJS.Timeout
  const close = () => {
    const face = document.getElementById('Face')
    if (face)
      face.classList.add('blink')

    const delay = random(150, 400, true)
    if (timeout)
      clearTimeout(timeout)
    timeout = setTimeout(open, delay)
  }
  const open = () => {
    const face = document.getElementById('Face')
    if (face)
      face.classList.remove('blink')
    
    const delay = random(3000, 5000, true)
    if (timeout)
      clearTimeout(timeout)
    timeout = setTimeout(close, delay)
  }

  const followMouse = (event: any) => {
    const pupil = document.getElementById('Pupil')
    const iris = document.getElementById('Iris')
    const eye = document.getElementById('Eye')

    if (!pupil || !iris || !eye)
      return
    
    const diff = -(eye.getBoundingClientRect().top - event.clientY) / 10
    const movementY = clamp(diff, -8, 15)
    const movementX = clamp(movementY/3, 0, 10)

    pupil.style.transform = `translate(${movementX}px,${movementY}px)`
    iris.style.transform = `translate(${movementX}px,${movementY}px)`
  }

  useEffect(() => {
    document.addEventListener('mousemove', followMouse)
    document.addEventListener('mousedown', close)
    open()

    return () => {
      clearTimeout(timeout)
      document.removeEventListener('mousemove', followMouse)
      document.removeEventListener('mousedown', close)
    }
  })

  return (
    <div className="face-wrapper">
      <FaceSVG></FaceSVG>
    </div>
  )
}

export default Face