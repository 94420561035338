import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import sanityClient from "../Sanity/client"
import { Container, Card, Row, Col, ListGroup, Nav } from 'react-bootstrap'
import LoadingBlogCard from "../LoadingBlogCard"
import { Helmet } from 'react-helmet'

import './BlogOverview.scss'

function prettyDate(time: string) {
    var date = new Date((time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        day_diff = Math.floor(diff / 86400);

    if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31) return;

    // eslint-disable-next-line
    return day_diff === 0 && (diff < 60 && "just now" || diff < 120 && "1 minute ago" || diff < 3600 && Math.floor(diff / 60) + " minutes ago" || diff < 7200 && "1 hour ago" || diff < 86400 && Math.floor(diff / 3600) + " hours ago") || day_diff == 1 && "yesterday" || day_diff < 7 && day_diff + " days ago" || day_diff < 31 && Math.ceil(day_diff / 7) + " weeks ago";
}

const BlogOverview = () => {
    const [postsData, setPostsData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const search = useLocation().search
    let category = new URLSearchParams(search).get('category')
    const categoryFilter = category ? `&& "${category}" in categories[]->slug.current` : ''
    category = category || "/blog"
    console.log(categoryFilter)
    
    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type == "post" ${categoryFilter}] {
                title,
                gist,
                slug,
                categories[] -> {
                    _id,
                    title,
                    slug
                },
                publishedAt,
                mainImage{
                    asset->{
                        _id,
                        url
                    }
                }
            }`
        )
        .then((data) => setPostsData(data))
        .catch(console.error)

        sanityClient
        .fetch(
            `*[_type == "category"] {
                title,
                slug
            }`
        )
        .then((data) => setCategoryData(data))
        .catch(console.error)
    }, [categoryFilter])

    console.log(postsData)
    console.log(categoryData)

    return (
        <Container className="App blog-overview">
            <Helmet>
                <title>Blog | Sander Vanhove</title>
                <meta name="twitter:title" content="Blog | Sander Vanhove"/> 

                <meta property="og:title" content="Blog | Sander Vanhove"/>
                <meta property="og:url" content="https://www.sandervanhove.com/blog/" />
            </Helmet>

            <h1 className="mb-4 mt-1">Sander Vanhove's Blog</h1>
            <Row>
                <Col md={2}>
                    <Link to="/"><h3>Home</h3></Link>
                </Col>
                <Col md={10}>
                    <Nav className="justify-content-end" variant="pills" defaultActiveKey="/blog" activeKey={category}>
                        <Nav.Item>
                            <Nav.Link href="/blog">All</Nav.Link>
                        </Nav.Item>
                        {
                            categoryData.map((category: any) => (
                                <Nav.Item>
                                    <Nav.Link href={`/blog?category=${category.slug.current}`} eventKey={category.slug.current}>{category.title}</Nav.Link>
                                </Nav.Item>
                            ))
                        }
                    </Nav>
                </Col>
            </Row>
            
            <Row xs={1} sm={2} md={3} className="blogpost-grid">
            {
                postsData.map((post: any) => (
                <Col key={post.slug.current}>
                    <Card>
                        <Link to={"/blog/" + post.slug.current}>
                            <Card.Img variant="top" src={post.mainImage.asset.url} alt="{post.title}" />
                        </Link>
                        <Card.Body>
                            <Link to={"/blog/" + post.slug.current}>
                                <Card.Title>{post.title}</Card.Title>
                            </Link>
                            <Card.Text>{post.gist}</Card.Text>
                        </Card.Body>

                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                {
                                    post.categories.map((category: any) => (
                                        <Link to={`?category=${category.slug.current}`} key={category.slug.current}>{category.title}</Link>
                                    ))
                                }
                            </ListGroup.Item>
                        </ListGroup>

                        <Card.Footer>
                            <small className="text-muted">Published {prettyDate(post.publishedAt)}.</small>
                        </Card.Footer>
                    </Card>
                </Col>
            ))}
            {
                postsData.length === 0 && Array.from({length: 6}).map((_, index) => (
                <Col key={index}>
                    <LoadingBlogCard></LoadingBlogCard>
                </Col>
                ))
            }
            </Row>
        </Container>
    )
}

export default BlogOverview