import React from "react"
import LandingPage from "../LandingPage"

import bouncyBoxIcon from './bouncybox_icon.png'

import screenshot1 from './30.png'
import screenshot2 from './jump.png'
import screenshot3 from './stand.png'

const BouncyBoxTheme = React.lazy(() => import('./BouncyBoxTheme'))

const BouncyBoxLandingPage = () => {
    return (
        <div className="bouncy-box-landingpage">
            <React.Suspense fallback={<></>}>
                <BouncyBoxTheme />
            </React.Suspense>
            <LandingPage
                gameName="Bouncy Box"
                shortDescription={`Bounce like no other box has bounced before in this super fun casual game!

Use the easy one-tap controls to determine your jump height, but don't let the simplicity of the controls fool you!`}
                icon={bouncyBoxIcon}
                introductionVideo="https://www.sandervanhove.com/bouncyboxgameplay.mp4"
                appStoreLink="https://apps.apple.com/be/app/bouncy-box/id1575193063"
                googlePlayLink="https://play.google.com/store/apps/details?id=com.sandervanhove.bouncybox"
                presskitLink="/presskit/"
                features={[
                    "Easy one-tap controls",
                    "Hard to master",
                    "Leader boards"
                ]}
                screenshots={[
                    screenshot3,
                    screenshot2,
                    screenshot1,
                ]}
            />
        </div>
    )
}

export default BouncyBoxLandingPage