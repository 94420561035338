import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Container, ProgressBar, Table } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

import './plant-monitor.scss'

const PlantMonitor = () => {
    const [data, setData] = useState({
        humidity: 'loading..',
        temperature: 'loading..',
        light: 'loading..',
        moisture: undefined,
        date: 'loading..',
        image_date: 'loading..',
    })

    console.log(data)

    useEffect(() => {
        axios('https://raw.githubusercontent.com/SanderVanhove/plant-monitor-data/master/values.json')
            .then(response => {
                setData(response.data)
            })
    }, [])

    let moisture_bar = (<ProgressBar className="shadow" animated variant="info" now={100} label="loading.."/>)
    let moisture_message = ''
    if (data.moisture !== undefined) {
        const moisture_level = data.moisture || 0
        const moisture_percent = (moisture_level / 2000) * 100

        let color = 'success'
        if (moisture_level < 200) {
            color = 'danger'
            moisture_message = 'Tell Sander he\'s dehydrating his plants!'
        }
        if (moisture_level > 1500) {
            color = 'danger'
            moisture_message = 'Tell Sander he\'s drowning his plants!'
        }

        moisture_bar = (<ProgressBar className="shadow" variant={color} now={moisture_percent} label={`${moisture_level}`} />)
    }

    const lux = data.light === 'loading..' ? data.light : Math.round(parseInt(data.light) / 1000 * 350)

    return (
        <Container>
            <Helmet>
                <title>Plant Monitor | Growlab | Sander Vanhove</title>
            </Helmet>
            <h1 className="mb-4 mt-1">Sander Vanhove's <a href="https://growlab.dev">#Growlab</a></h1>

            <h6>Stats ({data.date} UTC):</h6>
            <Table className="stats-table w-50 mb-4 shadow" striped hover variant="dark">
                <tbody>
                    <tr>
                    <th>Temperature</th>
                    <td>{data.temperature} °C</td>
                    </tr>
                    <tr>
                    <th>Humidity</th>
                    <td>{data.humidity} %</td>
                    </tr>
                    <tr>
                    <th>Light</th>
                    <td>{lux} lux</td>
                    </tr>
                </tbody>
            </Table>

            <h6>Soil Moisture ({data.date} UTC):</h6>
            {moisture_bar}
            {moisture_message &&
            (<p className="text-center"><a href="https://twitter.com/SanderVhove">{moisture_message}</a></p>)}
            
            <h6 className="mt-4">Latest Picture ({data.image_date} UTC):</h6>
            <img className="latest-image shadow mt-2 mb-8" src="https://github.com/SanderVanhove/plant-monitor-data/blob/master/pic.jpg?raw=true" alt="The latest plant progress."/>

            <h6 className="mt-4">Info</h6>
            <p><a href="https://growlab.dev">#Growlab</a> is a community project about technology and gardening. Check out the official app on <a href="https://github.com/alexellis/growlab">Github</a>. Or take a peak at my setup's code over <a href="https://github.com/SanderVanhove/plant-monitor">here</a>.</p>
        </Container>
    )
}

export default PlantMonitor