import { Col, Row, Image } from 'react-bootstrap'
import './games.scss'

import asteroidBeltIcon from "./icons/asteroid_belt_icon.png"
import taartIcon from "./icons/taart_icon.png"
import lightThemUpIcon from "./icons/light_them_up_icon.png"
import dinopultIcon from "./icons/dinopult_icon.png"
import shatteredEarthIcon from "./icons/shattered_earth_icon.png"
import diceVsMonstersIcon from "./icons/dice_vs_monsters_icon.png"
import justJumpIcon from "./icons/just_jump_icon.png"
import jumpViaCactusIcon from "./icons/jump_via_cactus_icon.png"
import mendingAHearthIcon from "./icons/mending_a_heart_icon.png"
import metalNomadIcon from "./icons/metal_nomad_icon.png"
import ratIncIcon from "./icons/rat_inc_icon.png"
import keepItAlightIcon from "./icons/keep_it_alight_icon.png"
import discoDeathRaceIcon from "./icons/disco_death_race_icon.png"
import dreamJobIcon from "./icons/dream_job_icon.png"
import eggIcon from "./icons/egg_icon.png"
import paintventureIcon from "./icons/paintventure_icon.png"
import ijdkaIcon from "./icons/ijdka_icon.png"
import headbuttDinoIcon from "./icons/headbutt_dino_icon.png"
import rubblarIcon from "./icons/rubblar_icon.png"

import silentDotIcon from "./icons/silent_dot_icon.png"
import giveMeBreadIcon from "./icons/give_me_bread_icon.png"

class Game {
    title: string = ""
    icon: string = ""
    link: string = ""
}

const commercial_games: Array<Game> = [
    {
        title: "Silent Dot",
        icon: silentDotIcon,
        link: "/silent-dot",
    },
    {
        title: "Bouncy Box",
        icon: justJumpIcon,
        link: "/bouncy-box",
    },
    {
        title: "Give me Bread",
        icon: giveMeBreadIcon,
        link: "https://play.google.com/store/apps/details?id=com.lamasaurus.brood",
    },
]

const jam_games: Array<Game> = [
    {
        title: "Rubblar",
        icon: rubblarIcon,
        link: "https://sandervanhove.itch.io/rubblar",
    },
    {
        title: "Headbutt Dino",
        icon: headbuttDinoIcon,
        link: "https://sandervanhove.itch.io/headbutt-dino",
    },
    {
        title: "I Just Don't Know Anymore",
        icon: ijdkaIcon,
        link: "https://sandervanhove.itch.io/i-just-dont-know-anymore",
    },
    {
        title: "PaintVenture",
        icon: paintventureIcon,
        link: "https://fadrikalexander.itch.io/paintventure",
    },
    {
        title: "Taart",
        icon: taartIcon,
        link: "https://sandervanhove.itch.io/taart",
    },
    {
        title: "Light Them Up",
        icon: lightThemUpIcon,
        link: "https://sandervanhove.itch.io/light-them-up",
    },
    {
        title: "Asteroid Belt",
        icon: asteroidBeltIcon,
        link: "https://sandervanhove.itch.io/asteroid-belt",
    },
    {
        title: "Dinopult",
        icon: dinopultIcon,
        link: "https://sandervanhove.itch.io/dinopult",
    },
    {
        title: "Dream Job",
        icon: dreamJobIcon,
        link: "https://dreamjobgame.itch.io/dream-job",
    },
    {
        title: "Shattered Earth",
        icon: shatteredEarthIcon,
        link: "https://sandervanhove.itch.io/shattered-earth",
    },
    {
        title: "Dice vs Monsters",
        icon: diceVsMonstersIcon,
        link: "https://sandervanhove.itch.io/dice-vs-monsters",
    },
    {
        title: "Just Jump",
        icon: justJumpIcon,
        link: "https://sandervanhove.itch.io/just-jump",
    },
    {
        title: "Eggs",
        icon: eggIcon,
        link: "https://twitter.com/SanderVhove/status/1381318082736619521",
    },
    {
        title: "Jump via Cactus",
        icon: jumpViaCactusIcon,
        link: "https://sandervanhove.itch.io/jump-via-cactus",
    },
    {
        title: "Mending a Hearth",
        icon: mendingAHearthIcon,
        link: "https://sandervanhove.itch.io/mending-a-heart",
    },
    {
        title: "Metal Nomad",
        icon: metalNomadIcon,
        link: "https://sandervanhove.itch.io/metal-nomad",
    },
    {
        title: "Rat Inc.",
        icon: ratIncIcon,
        link: "https://weeisfijn.itch.io/rat-inc",
    },
    {
        title: "Keep It Alight",
        icon: keepItAlightIcon,
        link: "https://sandervanhove.itch.io/keep-it-alight",
    },
    {
        title: "Disco Death Race",
        icon: discoDeathRaceIcon,
        link: "https://weeisfijn.itch.io/disco-death-race",
    },
]

const Games = () => {
    return (
        <>
            <div className="content-category">
                <h6>Released games:</h6>
                <Row md={4} sm={2} xs={2} className="justify-content-center">
                    {commercial_games.map((game, index) => (
                        <Col key={index}>
                            <div className="game-details">
                                <a href={game.link} target='blank'>
                                    <Image src={game.icon} alt={`Icon of ${game.title}`} />
                                </a>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            <div className="content-category">
                <h6>Small and Jam games:</h6>
                <Row md={5} sm={4} xs={3} className="justify-content-center">
                    {jam_games.map((game, index) => (
                        <Col key={index}>
                            <div className="game-details">
                                <a href={game.link} target='blank'>
                                    <Image src={game.icon} alt={`Icon of ${game.title}`} />
                                </a>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    )
}

export default Games
