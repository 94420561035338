import { Nav } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import About from './About'
import './content.scss'
import Games from './Games';

const Content = (props: any) => {
    const location = useLocation();
    return (
        <div className="content">
            <hr/>
            <Nav justify variant="pills" defaultActiveKey="/" activeKey={location.pathname}>
                <Nav.Item>
                    <Nav.Link href="/">Games</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/about/">About</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/blog">Blog</Nav.Link>
                </Nav.Item>
            </Nav>
            <hr/>

            <BrowserRouter>
                <Switch>
                    <Route component={About} path="/about" />
                    <Route component={Games} path="/" />
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default Content
