import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import sanityClient from "../Sanity/client"
//@ts-ignore
import BlockContent from "@sanity/block-content-to-react"
import imageUrlBuilder from "@sanity/image-url"
import { Helmet } from 'react-helmet'
import { Container, Card, Button } from 'react-bootstrap'
import ShareIcons from "../ShareIcons"

import './BlogPost.scss'
import MailForm from "../MailForm"

function prettyDate(time: string) {
    var date = new Date((time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        day_diff = Math.floor(diff / 86400);

    if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31) return;

    // eslint-disable-next-line
    return day_diff === 0 && (diff < 60 && "just now" || diff < 120 && "1 minute ago" || diff < 3600 && Math.floor(diff / 60) + " minutes ago" || diff < 7200 && "1 hour ago" || diff < 86400 && Math.floor(diff / 3600) + " hours ago") || day_diff == 1 && "yesterday" || day_diff < 7 && day_diff + " days ago" || day_diff < 31 && Math.ceil(day_diff / 7) + " weeks ago";
}

const builder = imageUrlBuilder(sanityClient)
function urlFor(source: any) {
  return builder.image(source)
}

const BlogPost = () => {
    const [postData, setPostData] = useState({
        title: "",
        gist: "",
        name: "",
        mainImage: {
            url: "",
        },
        body: "",
        categories: [],
        publishedAt: "0",
        patreonText: "",
        patreonLink: "",
        slug: {
            current: "",
        }
    })
    const { slug } = useParams() as any

    useEffect(() => {
        sanityClient
        .fetch(
            `*[slug.current == $slug]{
                publishedAt,
                title,
                slug,
                gist,
                categories[] -> {
                    _id,
                    title,
                    slug
                },
                mainImage{
                    asset->{
                        _id,
                        url
                    }
                },
                body,
                patreonLink,
                patreonText,
            }`,
            { slug }
        )
        .then((data) => setPostData(data[0]))
        .catch(console.error)
    }, [slug])

    return (
        <Container className="App blog-post">
            <Helmet>
                <title>{postData.title} | Blog | Sander Vanhove</title>
                <meta name="twitter:title" content={`${postData.title} | Blog | Sander Vanhove`}/> 
                <meta name="twitter:description" content={postData.gist}/> 
                <meta name="twitter:image" content={postData.mainImage.url}/>
                <meta name="twitter:image:alt" content={postData.title}/>

                <meta property="og:title" content={`${postData.title} | Blog | Sander Vanhove`}/>
                <meta property="og:url" content={`https://www.sandervanhove.com/blog/${postData.slug.current}`} />
                <meta property="og:description" content={postData.gist}/> 
                <meta property="og:image" content={postData.mainImage.url}/>
            </Helmet>

            <h1 className="mb-4 mt-1">Sander Vanhove's Blog</h1>
            <Link to="/blog"><h3>Back</h3></Link>

            <div className="post-content">
                <Card>
                    <Card.Img variant="top" src={urlFor(postData.mainImage).url() as string} alt="{post.title}" />
                    <Card.Body>
                        <Card.Title as="h2">{postData.title}</Card.Title>

                        <Card.Text className="post-categories">
                            {
                                postData.categories.map((category: any) => (
                                    <Link to={`/blog?category=${category.slug.current}`}>{category.title}</Link>
                                ))
                            }
                        </Card.Text>

                        <Card.Text className="post-gist">{postData.gist}</Card.Text>

                        <Card.Text className="post-text">
                            <BlockContent
                                blocks={postData.body}
                                projectId={sanityClient.clientConfig.projectId}
                                dataset={sanityClient.clientConfig.dataset} />
                        </Card.Text>

                        <Card.Text className="post-cta">
                            {postData.patreonText} Check out <a href={postData.patreonLink}>my bonus Patreon post</a>. 
                            <div className="post-cta-button">
                                <a href="https://www.patreon.com/sandervanhove">
                                    <Button><i className="fab fa-patreon"></i> Patreon</Button>
                                </a>
                            </div>
                        </Card.Text>
                    </Card.Body>

                    <Card.Footer>
                        <small className="text-muted">Published {prettyDate(postData.publishedAt)}.</small>
                        <ShareIcons shareUrl={`https://www.sandervanhove.com/blog/${postData.slug.current}`} title={postData.title} imageUrl={postData.mainImage.url}></ShareIcons>
                    </Card.Footer>
                </Card>
            </div>

            <MailForm></MailForm>
        </Container>
    )
}

export default BlogPost