import './header.scss'
import { Fragment } from 'react'

const Header = () => {
  const contactMethods: { [key: string]: string } = {
    Twitter: 'https://twitter.com/SanderVhove',
    Itch: 'https://lamasaurus.itch.io/',
    Youtube: 'https://www.youtube.com/c/SanderVanhove',
    Patreon: 'https://www.patreon.com/sandervanhove',
    Github: 'https://github.com/SanderVanhove',
    Instagram: 'https://www.instagram.com/sandervhove',
    LinkedIn: 'https://www.linkedin.com/in/sander-vanhove-21899771/',
    Mastodon: 'https://mastodon.gamedev.place/@SanderVanhove',
    Mail: 'mailto:sandervhove@gmail.com',
  }

  const separator = (<> | </>)

  const renderedContactMethods = Object.keys(contactMethods).map((method, index) => {
    return (
      <Fragment key={method}>
        <a rel={method === 'Mastodon' ? "me" : ""} href={contactMethods[method]}>{method}</a>
        {index !== Object.keys(contactMethods).length - 1 && separator}
      </Fragment>)
  })

  return (
    <header className="info-header">
      <h1>Sander Vanhove</h1>

      <address>
        {renderedContactMethods}
      </address> 

    </header>
  )
}

export default Header
